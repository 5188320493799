exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-favorites-tsx": () => import("./../../../src/pages/favorites.tsx" /* webpackChunkName: "component---src-pages-favorites-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx-content-file-path-src-posts-choose-data-visualizations-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/opt/build/repo/src/posts/choose-data-visualizations.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-posts-choose-data-visualizations-mdx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-cheatsheet-tsx-content-file-path-src-posts-aws-cli-cheatsheet-for-developers-mdx": () => import("./../../../src/templates/Cheatsheet.tsx?__contentFilePath=/opt/build/repo/src/posts/aws-cli-cheatsheet-for-developers.mdx" /* webpackChunkName: "component---src-templates-cheatsheet-tsx-content-file-path-src-posts-aws-cli-cheatsheet-for-developers-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-and-deploy-spa-on-aws-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/build-and-deploy-spa-on-aws.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-and-deploy-spa-on-aws-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-data-scraper-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/build-data-scraper.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-data-scraper-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-image-processing-pipeline-on-aws-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/build-image-processing-pipeline-on-aws.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-image-processing-pipeline-on-aws-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-restful-api-on-aws-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/build-restful-api-on-aws.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-build-restful-api-on-aws-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-guide-to-dynamodb-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/guide-to-dynamodb.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-guide-to-dynamodb-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-handle-streaming-responses-in-lambda-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/handle-streaming-responses-in-lambda.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-handle-streaming-responses-in-lambda-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-src-posts-leverage-git-history-for-business-impact-mdx": () => import("./../../../src/templates/Tutorial.tsx?__contentFilePath=/opt/build/repo/src/posts/leverage-git-history-for-business-impact.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-src-posts-leverage-git-history-for-business-impact-mdx" */)
}

