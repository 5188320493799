import React, { ReactNode } from "react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  LightBulbIcon,
} from "@heroicons/react/20/solid";

export function ImportantAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-blue-400 bg-blue-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon aria-hidden="true" className={`h-5 w-5 text-blue-400`} />
        </div>
        <div className={`ml-3 text-sm text-blue-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}

export function TipAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-green-400 bg-green-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <LightBulbIcon aria-hidden="true" className={`h-5 w-5 text-green-400`} />
        </div>
        <div className={`ml-3 text-sm text-green-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}

export function NoteAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-blue-400 bg-blue-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon aria-hidden="true" className={`h-5 w-5 text-blue-400`} />
        </div>
        <div className={`ml-3 text-sm text-blue-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}

export function WarningAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon aria-hidden="true" className={`h-5 w-5 text-yellow-400`} />
        </div>
        <div className={`ml-3 text-sm text-yellow-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}

export function DangerAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-red-400 bg-red-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon aria-hidden="true" className={`h-5 w-5 text-red-400`} />
        </div>
        <div className={`ml-3 text-sm text-red-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}

export function InfoAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-blue-400 bg-blue-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon aria-hidden="true" className={`h-5 w-5 text-blue-400`} />
        </div>
        <div className={`ml-3 text-sm text-blue-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}

export function SuccessAlert({ children }: { children: ReactNode }) {
  return (
    <div className={`border-l-4 border-green-400 bg-green-50 p-4 mt-6 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon aria-hidden="true" className={`h-5 w-5 text-green-400`} />
        </div>
        <div className={`ml-3 text-sm text-green-700 not-prose`}>{children}</div>
      </div>
    </div>
  );
}
