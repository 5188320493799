import React from "react";
import { GitHubIcon, LinkedInIcon, MailIcon } from "@/components/Icons";

export function SocialLink({ icon: Icon, ...props }) {
  return (
    <a className="group -m-1 p-1" target="_blank" rel="noopener noreferrer" {...props}>
      <Icon className="h-6 w-6 fill-zinc-500 transition group-hover:fill-zinc-600 dark:fill-zinc-400 dark:group-hover:fill-zinc-300" />
    </a>
  );
}

export function GitHubLink() {
  return <SocialLink href="https://github.com/cliffpyles" aria-label="Follow on GitHub" icon={GitHubIcon} />;
}

export function LinkedInLink() {
  return (
    <SocialLink href="https://www.linkedin.com/in/cliffpyles/" aria-label="Follow on LinkedIn" icon={LinkedInIcon} />
  );
}
