// gatsby-browser.js

import React from "react";
import { Providers } from "./src/components/Providers";
import AppLayout from "./src/components/AppLayout";
import "./src/styles/global.css";

export const wrapPageElement = ({ element, props }) => {
  return (
    <Providers>
      <AppLayout {...props}>{element}</AppLayout>
    </Providers>
  );
};
