import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";

function usePathname() {
  const location = useLocation();
  return location.pathname;
}

// Create a Theme Context
const ThemeContext = createContext({
  theme: "light",
  setTheme: (theme: string) => {},
});

// Custom hook to use the Theme context
export function useTheme() {
  return useContext(ThemeContext);
}

// ThemeProvider component to wrap around the app
export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState("system");
  const [resolvedTheme, setResolvedTheme] = useState("light");

  // useEffect(() => {
  //   const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  //   if (theme === "system") {
  //     setResolvedTheme(systemTheme);
  //   } else {
  //     setResolvedTheme(theme);
  //   }
  // }, [theme]);

  // // Save theme changes in localStorage if needed
  // useEffect(() => {
  //   document.documentElement.setAttribute("data-theme", resolvedTheme);
  // }, [resolvedTheme]);

  return <ThemeContext.Provider value={{ theme: resolvedTheme, setTheme }}>{children}</ThemeContext.Provider>;
}

// ThemeWatcher component that detects changes in system theme preference
function ThemeWatcher() {
  const { theme, setTheme } = useTheme();

  // useEffect(() => {
  //   const media = window.matchMedia("(prefers-color-scheme: dark)");
  //   const systemTheme = media.matches ? "dark" : "light";

  //   const onMediaChange = () => {
  //     if (theme === "system") {
  //       setTheme(media.matches ? "dark" : "light");
  //     }
  //   };

  //   media.addEventListener("change", onMediaChange);
  //   return () => {
  //     media.removeEventListener("change", onMediaChange);
  //   };
  // }, [theme, setTheme]);

  return null;
}

// Utility hook to store the previous value of a prop or state
function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// Context to store the previous pathname
export const AppContext = createContext<{ previousPathname?: string }>({});

// Providers component that wraps the app
export function Providers({ children }: { children: React.ReactNode }) {
  // const pathname = usePathname();
  // const previousPathname = usePrevious(pathname);

  return (
    <AppContext.Provider value={{ previousPathname: "/" }}>
      <ThemeProvider>
        <ThemeWatcher />
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
}
