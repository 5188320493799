import React, { ReactNode } from "react";
import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import {
  ImportantAlert,
  TipAlert,
  NoteAlert,
  WarningAlert,
  DangerAlert,
  InfoAlert,
  SuccessAlert,
} from "@/components/Alert";
import { CodeBlock } from "@/components/CodeBlock";
import { BentoGrid, GridItem } from "@/components/Grid";
const shortcodes = {
  // element overrides
  code: (props) => {
    if (props.className) {
      return <CodeBlock {...props} />;
    }
    return <code {...props} />;
  },
  pre: (props) => <div className="rounded overflow-hidden" {...props} />,
  p: (props) => {
    if (
      React.Children.toArray(props.children).some(
        (child) => typeof child === "object" && (child.type === "pre" || child.type === "code")
      )
    ) {
      return <>{props.children}</>;
    }
    return <p {...props} />;
  },

  // navigation components
  Link,

  // image components
  StaticImage,

  // alert components
  ImportantAlert,
  TipAlert,
  NoteAlert,
  WarningAlert,
  DangerAlert,
  InfoAlert,
  SuccessAlert,

  // grid components
  BentoGrid,
  GridItem,
};

export default function AppLayout({ children }: { children: ReactNode }) {
  return (
    <div className="AppLayout flex w-full">
      <div className="fixed inset-0 flex justify-center sm:px-8">
        <div className="flex w-full max-w-7xl lg:px-8">
          <div className="w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20" />
        </div>
      </div>
      <div className="mx-auto flex w-full flex-col max-w-4xl px-4 sm:max-w-5xl md:max-w-6xl lg:max-w-7xl xl:max-w-screen-xl">
        <Header />
        <MDXProvider components={shortcodes}>
          <main className="flex-auto">{children}</main>
        </MDXProvider>
        <Footer />
      </div>
    </div>
  );
}
