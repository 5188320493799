import clsx from "clsx";
import React from "react";

export function Grid({ children, className, columns, rows }) {
  const columnMap = {
    1: "grid-cols-1",
    2: "grid-cols-2",
    3: "grid-cols-3",
    4: "grid-cols-4",
    5: "grid-cols-5",
    6: "grid-cols-6",
    7: "grid-cols-7",
    8: "grid-cols-8",
    9: "grid-cols-9",
  };
  const rowMap = {
    1: "grid-rows-1",
    2: "grid-rows-2",
    3: "grid-rows-3",
    4: "grid-rows-4",
    5: "grid-rows-5",
    6: "grid-rows-6",
    7: "grid-rows-7",
    8: "grid-rows-8",
    9: "grid-rows-9",
  };
  const columnClassName = columnMap[columns] ? columnMap[columns] : columnMap[4];
  const rowClassName = rowMap[rows] ? rowMap[rows] : rowMap[4];

  return <div className={clsx(className, "grid", "items-end", columnClassName, rowClassName)}>{children}</div>;
}

export function BentoGrid({ children, className }) {
  const type1 = "grid grid-flow-row-dense grid-cols-5 grid-rows-auto gap-4";
  const type2 = "grid grid-flow-row auto-rows-max";
  const type3 = "grid grid-cols-5 gap-4";
  const type4 = "grid grid-flow-col auto-cols-max";
  const type5 = "grid grid-cols-4 gap-4 auto-rows-auto grid-flow-dense";
  const type6 = "columns-3";
  const type7 = "columns-3 flex flex-wrap";

  return <div className={clsx(type7, className)}>{children}</div>;
}

// Main item spanning two rows on the left
export function GridItem({ children, columns, rows }) {
  const columnMap = {
    1: "col-span-1",
    2: "col-span-2",
    3: "col-span-3",
    4: "col-span-4",
    5: "col-span-5",
  };
  const rowMap = {
    1: "col-span-1",
    2: "col-span-2",
    3: "col-span-3",
    4: "col-span-4",
    5: "col-span-5",
  };
  const columnClass = columnMap[columns] ? columnMap[columns] : columnMap[1];
  const rowClass = rowMap[rows] ? rowMap[rows] : rowMap[1];
  const className = clsx(columnClass, rowClass);
  return <div className="w-sm max-w-sm inline-block">{children}</div>;
}
