import React, { useState } from "react";
import { ClipboardIcon, CheckIcon } from "@heroicons/react/24/outline";
import Editor from "@monaco-editor/react";

export const CodeBlock = ({ children, className, ...props }) => {
  const [copied, setCopied] = useState(false);
  const language = className?.replace("language-", "") || "text";
  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  if (className && className.includes("language-")) {
    const lineHeight = 18;
    const height = Math.max(children.split("\n").length * lineHeight, lineHeight) + 10;

    return (
      <div className="not-prose rounded overflow-hidden border border-gray-300 dark:border-gray-700">
        <div className="flex justify-between items-center bg-gray-800 text-gray-200 px-3 py-1">
          <span className="text-xs font-mono">{language}</span>
          <button
            onClick={handleCopy}
            className="bg-transparent p-0 border-none text-gray-300 dark:text-gray-300 hover:text-blue-400 dark:hover:text-blue-400 focus:outline-none"
          >
            {copied ? (
              <CheckIcon className="w-5 h-5 text-green-400 dark:text-green-400" />
            ) : (
              <ClipboardIcon className="w-5 h-5 text-gray-300 dark:text-gray-300" />
            )}
          </button>
        </div>
        <Editor
          theme="vs-dark"
          defaultLanguage={className?.replace?.("language-", "") || "text"}
          defaultValue={children}
          height={height}
          options={{
            readOnly: true, // Make the editor read-only
            scrollBeyondLastLine: false, // Prevent scrolling beyond the last line
            minimap: { enabled: false }, // Disable minimap for a cleaner look
            lineNumbers: "on", // Hide line numbers
            glyphMargin: false, // Hide glyph margin (used for debugging)
            folding: false, // Disable code folding
            lineDecorationsWidth: 10, // Remove padding for line decorations
            overviewRulerLanes: 0, // Disable overview ruler (right gutter)
            renderLineHighlight: "none", // Disable line highlight
            scrollbar: {
              vertical: "hidden", // Hide vertical scrollbar
              horizontal: "hidden", // Hide horizontal scrollbar
            },
            contextmenu: false, // Disable right-click context menu
            scrollBeyondLastColumn: 0, // Prevent scrolling past the last column
            wordWrap: "off", // Enable word wrap for long lines
            tabSize: 2, // Set default tab size (adjust as needed)
            highlightActiveIndentGuide: false, // Disable indentation guides
            renderIndentGuides: false, // Disable render of indent guides
            // automaticLayout: true, // Auto-resize with layout changes
            automaticLayout: true, // Auto-resize with layout changes
            cursorStyle: "line",
            cursorWidth: 0,
            renderFinalNewline: false,
            lineHeight,
            padding: {
              top: 10,
              bottom: 0,
            },
          }}
        />
      </div>
    );
  }

  return (
    <div className="not-prose inline-flex items-center space-x-2 rounded bg-gray-200 px-2 py-1 text-sm font-mono text-gray-800">
      <code className="overflow-hidden">{children}</code>
    </div>
  );
};
